/* 
// 8. Features
*/

.feather-bg-img {
    background-position: center center;
    background-size: cover;
}

.icon-feautre-1 {
    font-size: 40px;
    color: #3e87db;
}

.icon-feautre-2 {
    font-size: 40px;
    color: #fd9644;
}

.icon-feautre-3 {
    font-size: 40px;
    color: #46978d;
}

.icon-feautre-4 {
    font-size: 40px;
    color: #ee5253;
}

.icon-feautre-5 {
    font-size: 40px;
    color: #3f51b5;
}

.icon-feautre-6 {
    font-size: 40px;
    color: #00bcd4;
}

.icon-feautre-7 {
    font-size: 40px;
    color: #f5bc42;
}

.icon-feautre-8 {
    font-size: 40px;
    color: #2ac081;
}

.icon-feautre-9 {
    font-size: 40px;
    color: #c43eae;
}

.icon-feautre-10 {
    font-size: 40px;
    color: #8bc43e;
}

.icon-feautre-11 {
    font-size: 40px;
    color: #6557d6;
}

.icon-feautre-12 {
    font-size: 40px;
    color: #02bbb2;
}

.feature-text {
    font-size: 15.5px !important;
}